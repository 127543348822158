.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Oxygen', sans-serif;
  width: 100%;
  margin: 0 auto;
}
header {
  padding-top: 2vh;
  padding-bottom: 2vh;
  height: 8%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: whitesmoke;
  border-bottom: 2px solid black;
}
header h1 {
  padding-bottom: 2%;
  font-size: 2rem;
  font-family: 'Bebas Neue', cursive;
}
header a {
  padding: 3%;
  font-family: 'Bebas Neue', cursive;
}
.intro {
  padding: 25vh 0;
  width: 100%;
  background-image: url("./files/bg.gif");
}
.introContainer {
  background-color: whitesmoke;
  padding: 3%;
  width: 56%;
  margin: 0 auto;
}
.intro h3 {
  background-color: whitesmoke;
  color: black;
  text-align: center;
  width: 98%;
  margin: 0 auto;
}
.skillContainer {
  margin-top: 3%;
}
.skillContainer h2 {
  padding-top: 2%;
}
.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.individualSkill {
  flex: 1 1 210px;
  margin: 0;
  padding: 1%;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 200px;
  width: auto;
  height: auto;
}
.individualSkill img {
  display: block;
  max-width: 100%;
  max-height: 150px;
  height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}
.contactContainer {
  width: 100%;
  background-image: url("./files/bg.gif");
}
.contacts {
  border: 2px solid black;
  background-color: whitesmoke;
  color: black;
  width: 75%;
  display: flex;
  flex-direction: column;
  padding: 1%;
  align-items: center;
  justify-content: center;
  margin: 1vh auto;
}
.contacts form {
  width: 85%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contacts h3 {
  padding-top: 2%;
}
.contacts label {
  padding: 3%;
  text-align: center;
  width: 94%;
  display: block;
}
.contacts textarea {
  width: 90%;
  margin: 3%;
}
.contacts input {
  width: 90%;
  margin: 3%;
}
.contacts .button {
  width: 45%;
  margin: 3%;
}
hr {
  width: 65%;
}
.projectsContainer {
  padding-top: 3%;
}
.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.individualProject {
  flex: 1 1 450px;
  margin: 4%;
  border: 2px solid black;
  border-radius: 2.5%;
  padding: 2%;
}
.projectDescription {
  white-space: pre-wrap;
  text-align: justify;
  line-height: 150%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 87.5%;
  margin: 0 auto;
}
.projectImage {
  margin: 0 auto;
}
.projectName {
  padding-top: 0;
}
.socialLinks {
  padding: 2%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.socialLinks a {
  padding: 1%;
  width: 80px;
}
.lambdaIMG {
  max-width: 250px;
  width: 100%;
}
a:link {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
  text-decoration: underline;
}
a:active {
  color: black;
  background-color: grey;
  text-decoration: underline;
}
a:visited {
  color: darkgrey;
  text-decoration: underline;
}
